/** @jsx jsx */

import { jsx } from 'theme-ui';
import meet28 from 'assets/logo-chat.svg';

const teamImageSx = {
  '.container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    width: '170px',
    marginRight: '10px',
    cursor: 'pointer',
    borderRadius: '10px',
    marginBottom: '30px',
  },
  '.text': {
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '30px',
    color: 'white',
  },

  '.logo': {
    position: 'relative',
    height: '120px',
    width: '100%',
    objectFit: 'contain',
    marginBottom: '50px',
  },
};

const TeamImage = ({ color, onClick }) => {
  return (
    <div sx={teamImageSx} onClick={onClick}>
      <div className="container" sx={{ backgroundColor: color }}>
        <h2 className="text">Meet 28</h2>
        <img src={meet28} alt="Meet 28 Logo" className="logo" />
      </div>
    </div>
  );
};

export default TeamImage;

/** @jsx jsx */
import React from 'react';
import { Component } from 'react';
import { jsx } from 'theme-ui';
import ReactCardFlip from 'react-card-flip';

import Navbar from 'components/Navbar';

import { footerHeight, headerHeight } from 'constants/Navbar';
import meet28 from 'assets/meet28.svg';
import fiveMeet28Cards from 'assets/five-meet28-cards.svg';
import twoMeet28Cards from 'assets/two-meet28-cards.svg';
import lastCardGreen from 'assets/last-card-green.svg';
import blankCard from 'assets/blank-card.svg';
import penguin from 'assets/penguin.gif';

window.React = React;

const landingSx = {
  '.landing-container': {
    height: `calc(100vh - ${headerHeight + footerHeight}px)`,
    overflowY: 'hidden',
  },

  '.top-items': {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    marginLeft: '50px',
    marginTop: '50px',
  },

  '.headline': {
    fontSize: '120px',
    fontWeight: 600,
    lineHeight: 0.6,
  },

  '.emblem': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '300px',
  },

  '.subline': {
    fontSize: '25px',
    fontWeight: 400,
  },

  '.flip-text-container': {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
  },

  '.flip-text': {
    color: theme => theme.colors.mainBlue,
    fontSize: '16px',
  },

  '.emblem img': {
    position: 'absolute',
  },

  '.logoCircle': {
    transition: 'transform .8s ease-in-out',
    WebkitTransition: '-webkit-transform .8s ease-in-out',
  },

  '.logoCircle:hover': {
    transform: 'rotate(360deg)',
    WebkitTransform: 'rotate(360deg)',
    cursor: 'pointer',
  },

  '.circleBg': {
    position: 'absolute',
    backgroundColor: theme => theme.colors.mainLightBlue,
    borderRadius: '100%',
    width: '250px',
    height: '250px',
  },

  '.popup': {
    position: 'absolute',
    transition: 'all 1s ease-in-out',
    opacity: 0,
    cursor: 'pointer',
  },
  '.popup-active': {
    opacity: 1,
    transform: 'translate(60px, -100px)',
  },

  '.static-cards': {
    position: 'absolute',
    marginTop: '16px',
    left: '50%',
    transform: 'translateX(-60%)',
    zIndex: 1,
  },

  '.last-card': {
    position: 'absolute',
    marginTop: '16px',
    left: 'calc(50% + 195px)',
    transform: 'translateX(-60%)',
    top: '320px',
    zIndex: 2,
  },

  '.card-text': {
    position: 'absolute',
    top: '30%',
    left: '20%',
    width: '160px',
    transform: 'rotate(8.01deg)',
  },

  '.rectangle1': {
    position: 'absolute',
    width: '246px',
    height: '340px',
    left: '-2%',
    top: '60%',

    backgroundColor: theme => theme.colors.ivory,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
    transform: 'rotate(20deg)',
  },

  '.rectangle2': {
    position: 'absolute',
    width: '246px',
    height: '340px',
    left: '78%',
    top: '-9%',

    backgroundColor: theme => theme.colors.mainGreen,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
    transform: 'rotate(-16deg)',
  },

  '.rectangle3': {
    position: 'absolute',
    width: '246px',
    height: '340px',
    left: '85%',
    top: '68%',

    backgroundColor: theme => theme.colors.gray,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
    transform: 'rotate(-30deg)',
  },

  // mobile things
  '.mobile-landing-container': {
    padding: '25px',
    marginTop: '10px',
  },

  '.mobile-emblem-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },

  '.mobile-headline-container': {
    textAlign: 'center',
  },

  '.mobile-headline': {
    fontSize: '80px',
    fontWeight: 600,
    lineHeight: 0.8,
  },

  '.mobile-subline': {
    fontSize: '16px',
    fontWeight: 400,
  },

  '.mobile-flip-text-container': {
    margin: 'auto',
    paddingTop: '20px',
    textAlign: 'center',
    fontSize: '14px',
    color: theme => theme.colors.mainBlue,
    width: '300px',
  },

  '.mobile-last-card': {
    position: 'absolute',
    marginTop: '16px',
    left: 'calc(50% + 85px)',
    transform: 'translateX(-60%)',
    top: '255px',
    zIndex: 2,
  },

  '.mobile-card-text': {
    position: 'absolute',
    top: '20%',
    left: '20%',
    width: '130px',
    transform: 'rotate(8.01deg)',
  },
};

const predictions = [
  'Someone is thinking about you right now.',
  'The spark in your eyes is distracting.',
  'An egg of opportunity will fall into your lap this week.',
  'Someone from your past will emerge soon in your future.',
  'Help! I’m trapped inside the computer! Get me out!',
  'A strong current is not powerful enough to disrupt your flow.',
  'You may not believe in tarot cards, but I believe in you.',
  'This could be the start of something new.',
  'You will travel to great destinations in your life. Don’t fly solo!',
  'Your modesty is not appreciated enough.',
  'You will capture your audience’s attention',
  'Don’t let the haters tell you they don’t like your style!',
  'You’re only a few steps away from the next floor.',
  'Your warm presence will brighten somebody’s autumn.',
  'Like a salmon, your treacherous river journey will be worthwhile.',
  'Like a sponge, you will absorb the best of the world around you.',
  'Like a giraffe, your existing qualities will help you reach new heights.',
  'Like a turtle, come out of that protective shell every now and then!',
  'Like a sloth, live life at your own pace!',
  'Like a bear, a long rest is well-deserved and necessary this winter.',
  'Like a penguin, you’ll be part of the club soon.',
  'You might feel lonely, but you’re not alone!',
  'A not-insignificant amount of wealth will plop down from the skies.',
  'Fresh air will be present when you need to take a deep breath.',
  'A small star can still shine brightly.',
  'Don’t deny yourself of that creative outlet.',
  'When you come to an impasse, go towards a new beginning.',
  'You’ll turn all your Ls into Lessons - Chance the Rapper',
  'In a world full of lemons, be a lime.',
  'In a world full of snakes, be a worm.',
  'You’re going to sweat a lot today. Congratulations.',
  'You will forget your ID at least once. Congratulations.',
  'You’re going to use the bathroom today. Congratulations.',
  'You will see a squirrel today. Congratulations.',
  'The grass you see today will be especially grassy. Congratulations.',
  'You will get your shirt caught on a door on your way out. Congratulations.',
  'A coffee addiction is coming for you! Run before it’s too late.',
  'Your laptop will break down during finals season. Sorry.',
  'You will forget to drink enough water this semester.',
  'You will trip at least once. Sorry.',
  'You will get to eat a delicious cookie sometime this semester!',
  'You will pet a cute dog this week :)',
  'Not a fortune, but you look good today ;)',
  'You will have a bad hair day soon. Sorry.',
  'When Mercury is in retrograde, you will have a long and satisfying poop.',
  'Big gains are coming your way.',
  'You will find a shiny penny on the ground. Congratulations.',
  'Not a fortune, but your hair looks great today ;)',
  'Not a fortune, but you have really nice teeth :D',
  'Not a fortune, but you smell pretty good. ;)',
  'You will stumble across some chicken nuggets that fell on the floor. Big sad.',
];

class LiveLanding extends Component {
  state = {
    isFlipped: false,
    mobile: false,
    popupActive: false,
    prediction: predictions[Math.floor(Math.random() * predictions.length)],
  };

  handleCardClick = () => {
    this.setState({
      isFlipped: !this.state.isFlipped,
      prediction: this.state.isFlipped
        ? this.state.prediction
        : predictions[Math.floor(Math.random() * predictions.length)],
    });
  };

  // update window dimensions and sidebar properties
  updateWindowDimensions = () => {
    this.setState({
      mobile: window.innerWidth <= 1000,
    });
  };

  componentDidMount() {
    this.updateWindowDimensions();

    // resize handler event listener
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  handleEmblemClick = () => {
    this.setState({ popupActive: !this.state.popupActive });
  };

  renderDesktop = () => {
    const { totalUsers } = this.props;

    return (
      <div className="landing-container" sx={landingSx}>
        <div className="top-items">
          <div className="emblem-container">
            <div className="emblem" onClick={this.handleEmblemClick}>
              <img src={meet28} alt="Meet 28 Logo" style={{ height: 160 }} />
              <div
                className={`popup ${
                  this.state.popupActive ? 'popup-active' : ''
                }`}
              >
                <img src={penguin} alt="penguin" style={{ height: 80 }} />
              </div>
            </div>
          </div>
          <div className="headline-container">
            <div>
              <div className="headline">{totalUsers.toLocaleString()}</div>
              <br />
              <div className="subline">first-years and counting...</div>
            </div>
          </div>
        </div>
        <div className="flip-text-container">
          <div className="flip-text">
            What is your fortune for this year? Flip your top card to find out.
          </div>
        </div>
        <div className="static-cards">
          <img
            src={fiveMeet28Cards}
            alt="fiveMeet28Cards"
            style={{ height: 350 }}
          />
        </div>
        <div className="last-card">
          <ReactCardFlip
            isFlipped={this.state.isFlipped}
            flipDirection="vertical"
          >
            <div onClick={this.handleCardClick}>
              <img
                src={lastCardGreen}
                alt="lastCardGreen"
                style={{ height: 310, cursor: 'pointer' }}
              />
            </div>
            <div onClick={this.handleCardClick} style={{ cursor: 'pointer' }}>
              <img src={blankCard} alt="blankCard" style={{ height: 310 }} />
              <div className="card-text">{this.state.prediction}</div>
            </div>
          </ReactCardFlip>
        </div>
        <div className="rectangle1" />
        <div className="rectangle2" />
        <div className="rectangle3" />
      </div>
    );
  };

  renderMobile = () => {
    const { totalUsers } = this.props;

    return (
      <div className="mobile-landing-container" sx={landingSx}>
        <div className="mobile-emblem-container">
          <div>
            <div className="mobile-headline-container">
              <div className="mobile-headline">
                {totalUsers.toLocaleString()}
              </div>
              <div className="mobile-subline">first-years and counting...</div>
            </div>
          </div>
        </div>
        <div className="mobile-flip-text-container">
          <div className="mobile-flip-text">
            What is your fortune for this year? Flip your top card to find out.
          </div>
        </div>
        <div className="static-cards">
          <img
            src={twoMeet28Cards}
            alt="twoMeet28Cards"
            style={{ height: 270 }}
          />
        </div>
        <div className="mobile-last-card">
          <ReactCardFlip
            isFlipped={this.state.isFlipped}
            flipDirection="vertical"
          >
            <div onClick={this.handleCardClick}>
              <img
                src={lastCardGreen}
                alt="lastCardGreen"
                style={{ height: 260 }}
              />
            </div>
            <div onClick={this.handleCardClick}>
              <img src={blankCard} alt="blankCard" style={{ height: 260 }} />
              <div className="mobile-card-text">{this.state.prediction}</div>
            </div>
          </ReactCardFlip>
        </div>
      </div>
    );
  };

  render() {
    const { mobile } = this.state;
    return (
      <div className="test" sx={landingSx}>
        <Navbar isLanding={true} sx={landingSx}>
          {mobile ? this.renderMobile() : this.renderDesktop()}
        </Navbar>
      </div>
    );
  }
}

export default LiveLanding;

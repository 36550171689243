const collegePops = {
  Bowdoin: 1951,
  Brown: 6792,
  CEGEPS: 31065,
  CMU: 6622,
  Caltech: 987,
  Carleton: 2000,
  Claremont: 8500,
  Columbia: 8148,
  Cornell: 14743,
  Dartmouth: 4170,
  Emory: 7010,
  FIT: 7959,
  Furman: 2827,
  Harvard: 7133,
  'Harvard-MIT': 21864,
  Iowa: 22304,
  LMU: 6673,
  MIT: 4638,
  Macalester: 2049,
  McGill: 10411,
  NYU: 27444,
  Northeastern: 15156,
  OSU: 26644,
  Princeton: 4774,
  Queens: 16702,
  Smith: 2183,
  Tufts: 6114,
  'UC Berkeley': 31814,
  'UC Davis': 31162,
  UCSD: 31842,
  UCLA: 31636,
  UChicago: 7559,
  UIC: 22279,
  UPenn: 11155,
  USC: 21000,
  UToronto: 61690,
  'UW Madison': 47936,
  Vanderbilt: 7111,
  WashU: 7653,
  Yale: 4703,
};

export default collegePops;

/** @jsx jsx */

import { Component } from 'react';
import { jsx } from 'theme-ui';
import { Link } from 'react-router-dom';
import logo from 'assets/logo.png';

import Container from 'components/Container';
import Header from 'components/Header';

import { Mixpanel } from 'utils/mixpanel.js';

import { pageFAQSx } from 'pages/PageFAQ/PageFAQStyles';

export default class PageFAQ extends Component {
  state = {};

  // content has a key (which is the section title) and then HTML div that includes the contents of that section
  content = {
    'About Meet28': (
      <div>
        <h4>
          What <i>exactly</i> is Meet28? Without the jokes, please.
        </h4>
        <p>
          Meet28 is a friendship matching service for first-years run by
          Datamatch, an organization on Harvard’s campus, originally started as
          a free matchmaking service as a way for college students to find true
          love. Back in 1994, a bunch of Harvard students banded together to
          make this matchmaking system a reality for their campus – but as of
          recently, Datamatch has now spread to over 50 schools, with plans to
          expand to more! Take our Meet28 survey and leave it to us and our top
          secret Algorithm™ to find your best friend. Datamatch has become a
          tradition across colleges, with over tens of thousands of students
          signing up every year. Met your best friends through Meet28? Just wait
          until you meet the love of your life during Datamatch in February! If
          you are curious about participating, you can read more in the FAQ
          section “The Process.”
        </p>
        <h4>Thank god, I'm so lonely! Can I participate in Meet28?</h4>
        <p>
          If you are a first-year student at Harvard College, you're eligible!
        </p>
        <h4>What's your legal policy? Fair use policy?</h4>
        <p>Here's our expanded legal policy:</p>
        <div className="legal">
          No purchase necessary. Void in wacko states like Ohio and Michigan and
          where prohibited by law. Keep out of reach of children. Datamatch not
          liable for negligible use, unless consequences were good. Wait 2 hours
          after breaking up to use. Do not combine with alcohol. Do not operate
          heavy machinery while under the effects of Datamatch. Any views or
          opinions expressed therein are completely hilarious. Datamatch is
          provided as is without warranty. Copyright 1636.
        </div>
        <p>
          Otherwise, our current team policy, and the one we hope all Datamatch
          users will abide by is, "BE A GOOD PERSON." As for fair use, consider
          all users to receive a free permit from us.
        </p>
        <p>
          PS:{' '}
          <Link to={this.props.landing ? '/terms' : '/app/terms'}>Here</Link>{' '}
          are our full serious terms of service.
        </p>
        <h4>What happens to my information? Is it private?</h4>
        <p>
          Don't worry, we don't share your information with Facebook!! The
          Datamatch team personally touches your information only as much as is
          necessary to develop the Algorithm&trade; and resolve user issues.
          That promise extends to dating and social network information as well.
          We may collect some anonymous stats like usage statistics, but your
          name and contact info will be completely separate from such reports.{' '}
          <Link to={this.props.landing ? '/privacy' : '/app/privacy'}>
            Here's
          </Link>{' '}
          our privacy policy.
        </p>
      </div>
    ),
    'The Process': (
      <div>
        <h4>When does the survey open? When do results come out?</h4>
        <p>
          The survey opens at 12:01 AM EST on September 13th; the survey then
          closes a week later, on September 20th at 12:01 AM EST. Results come
          out later that day: stay posted!
        </p>
        <h4>I'm still a bit confused. What exactly do I have to do?</h4>
        <p>
          Just register and login using your college email, fill out your basic
          user info, our specially designed survey, and wait until September
          23rd for results to be released!
        </p>
        <h4>What do I do after results are released?</h4>
        <p>
          You will receive around 10 other users that the algorithm will have
          matched you with! Then, for the people you might want to meet or sound
          vaguely interesting, you can hit “Match” to express interest. The
          other person won’t know if you hit match! The only way they’ll know is
          if they also hit match—then, it’ll show that you have mutually
          matched.
        </p>
        <h4> What can I do with my mutual matches?</h4>
        <p>
          Afterwards, contact your matches using the website’s chat feature,
          through the social media handles they shared, or in whatever way you'd
          like.
        </p>
        <p>
          Warning: that button is one-way only, so only click if you're certain!
          Once both people in a match have toggled, an email will be sent out
          with further instructions that will guide you on claiming your free
          date. You'll have to wait or convince the other person if they haven't
          toggled yet.
        </p>
        <h4>I have the attention span of a Yale student.</h4>
        <p>tl;dr: Take survey; get new best friends.</p>
        <h4>
          I'm in a relationship, but I'd really love to participate. Is there a
          way to tell people I'm not romantically available?
        </h4>
        <p>
          Yes! Meet28 is completely platonic, meaning that its just made for you
          to meet new friends. You'll only be matched with other platonic users,
          or users who specify that they don't care, with matches in the latter
          case being explicitly labeled platonic.
        </p>
      </div>
    ),
    'The Survey': (
      <div>
        <h4>Who wrote these questions? They're so funny!</h4>
        <p>We stole them from fortune cookies.</p>
        <h4>Who wrote these questions? They're so boring!</h4>
        <p>We stole those from fortune cookies too.</p>
        <h4>
          I want to read this year's questions without taking part in the
          survey. Is that possible?
        </h4>
        <p>No.</p>
      </div>
    ),
    'The Algorithm™ / Results': (
      <div>
        <h4>Let's just get straight to it. Is the Algorithm&trade; random?</h4>
        <p>It can be anything you want it to be.</p>
        <h4>
          Okay, I get it. It's "proprietary artificial intelligence." *wink
          wink*
        </h4>
        <p>
          No, really! Though in the past less scrupulous Datamatch members have
          resorted to rolling 20-sided dice and examining the entrails of
          slaughtered animals, the modern Datamatch is fundamentally rooted in
          strong data analysis.
        </p>
        <h4>Right...</h4>
        <p>
          Well, it's still up to you whether to believe us or not. If you would
          like to know more, we're always looking for new members to help with
          programming and data analysis. Just shoot us an email!
        </p>
        <h4>So how accurate are these results?</h4>
        <p>
          Extremely. But though we can match you, we can't play God! It's up to
          you to contact your matches, and actually make something happen. ""You
          miss 100% of the shots you don't take." - Wayne Gretzky" - Michael
          Scott.
        </p>
        <h4>What’s up with all the shape thingies on the stats page?</h4>
        <p>
          We’ve used current and historical data to build nifty interactive data
          visualizations so we can see patterns in our data and keep track of
          trends, yadda yadda—basically, we want you to spend more time on our
          website so we’ve made some cool graphs to entertain you. Go ahead and
          check it out! This year, we've looked at sign ups, matches between
          dorms and schools, matches between hometowns, the way users answer
          questions, and much much more!
        </p>
        <h4>I don't like my results. What can I do about it?</h4>
        <p>
          What can you do about it? What can any of us do about it? Maybe don't
          let the existential dread get you today; go out there and meet someone
          new!
        </p>
        <h4>
          We met and had a great time and now we're best friends! We love you,
          Datamatch!
        </h4>
        <p>We love you, too. :) </p>
        <p>
          <a href="mailto:cupids@datamatch.me">Share</a> your success stories,
          we feed off of them!
        </p>
      </div>
    ),
    'General Help': (
      <div>
        <h4>AHH! AHH! AHHHHHH! SOMETHING BROKE! AHHHHHHH!</h4>
        <p>
          Okay, first: calm down. Next: let's work together to{' '}
          <a
            href="https://youtu.be/LinpRhB4aWU?t=19"
            rel="noopener noreferrer"
            target="_blank"
          >
            diagnose the problem
          </a>
          . Have you tried refreshing the page a couple times? What about
          logging out and logging back in? Incognito mode is nice too. *wink* If
          all else fails, email us and we'll be happy to help on a case by case
          basis.
        </p>
        <h4>
          So I’d totally take Datamatch, but I really don’t want to be matched
          with my ex...
        </h4>
        <p>
          Oof, yeah, been there… You can go to the “Settings” page and for our
          "Blocklist", submit up to 10 emails for people that you don’t want to
          be matched with!
        </p>
        <h4>
          I'm having an issue with submitting my information or survey answers,
          as well as load times.
        </h4>
        <p>
          When Datamatch initially opens, we're often flooded by log-ins. Try
          signing back in at a later time, when the load is lower.
        </p>
        <h4>
          I made an account and I signed up, but now I want to delete it. How
          can I do this?
        </h4>
        <p>
          Good news, kinda! You can now delete your account through your
          "Settings" page. Just know that every time an account is deleted, the
          algorithm sheds a single solitary tear at your absence, as if a
          miracle was interrupted.
        </p>
        <h4>
          My browser is giving me ugly formatting, and I'm having trouble
          logging in. What gives?
        </h4>
        <p>
          Some old versions of browsers, especially Safari and Internet
          Explorer, are known to have issues with the Datamatch website. Try
          switching to Chrome or Firefox or updating your browser version and
          seeing if that resolves the issue; if not, contact us.
        </p>
        <h4>I'm having issues on my phone.</h4>
        <p>Sounds like a personal problem. Maybe try using a real computer.</p>
        <h4>I have an idea to make Datamatch better!</h4>{' '}
        <p>
          Oooooh! Love to hear it!{' '}
          <a href="mailto:cupids@datamatch.me">Tell us about it!</a>
        </p>
        <h4>
          Everyone in Datamatch is so cool and smart and wonderful! How can I
          join Datamatch?
        </h4>{' '}
        <p>
          Why, thank you! We think you’re cool and smart and wonderful too.
          Email us at{' '}
          <a href="mailto:cupids@datamatch.me">cupids@datamatch.me</a>, we want
          to meet you!
        </p>
      </div>
    ),
  };

  componentDidMount() {
    // Prevent FAQ page event logging if embedded in the landing page
    if (!this.props.landing) {
      // Web analytics
      Mixpanel.track('FAQ_Page', {});
    }
  }

  render() {
    return (
      <Container>
        <div className="page-faq" sx={pageFAQSx}>
          <Header>About</Header>
          <div className="modal-box">
            <div>
              <img width="100%" src={logo} alt="Icon" />
              <br />
              <br />
              Meet28 has been brought to you by Datamatch, a student
              organization that brings friendship, food, and possibly more ;) to
              college students all around North America. In short, we play Cupid
              for thousands of undergraduates.
              <br />
              <br />
              Datamatch was founded back in 1994 by some Harvard students who
              decided that February was just much too sad of a time in
              Cambridge. They fatefully banded together on a cold, dark night
              ago to write questions matchmaking the rest of the student body,
              and the rest is history. More than 30 schools, one top secret
              Algorithm™, and 28 years later, Datamatch has become a Valentine’s
              Day tradition, with over tens of thousands of students annually
              signing up every year for fun questions, new connections, and free
              food (yes, sometimes there is such a thing as free lunch).
            </div>
            <div sx={{ color: '#7FBD8F', fontStyle: 'italic' }}>
              <br />
              If you’d like to join our team of Cupids,{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://forms.gle/VR3wjgiq9cMEU8ig6"
              >
                complete the interest form!
              </a>{' '}
            </div>
          </div>
          <Header>FAQs</Header>
          <div className="faq-container">
            {Object.keys(this.content).map(key => {
              return (
                <div key={key}>
                  <div
                    className={
                      this.state[key] ? 'faq-button -selected' : 'faq-button'
                    }
                    // if a section gets clicked, it expands itself. you have to click again to collapse it
                    onClick={() =>
                      this.setState({
                        [key]: this.state[key] ? null : key,
                      })
                    }
                  >
                    <i
                      className={
                        this.state[key]
                          ? 'fas fa-chevron-down'
                          : 'fas fa-chevron-right'
                      }
                    ></i>
                    {key}
                  </div>
                  {this.state[key] && (
                    <div className="faq-section">{this.content[key]}</div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    );
  }
}

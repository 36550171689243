export const theme = {
  breakpoints: ['640px', '1024px'],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    default: 'Times New Roman',
    body: 'Apercu, sans-serif',
    heading: 'Apercu, sans-serif',
    monospace: 'Apercu-Mono, monospace',
  },
  fontSizes: [10, 12, 14, 16, 20, 24, 32, 48],
  fontWeights: {
    body: 400,
    medium: 500,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.25,
    heading: 1.125,
  },

  /*:root {
  --dm-green:;
  --dm-ivory: #E7E8DA;
  --dm-light-green: #BEDBBD;
  --dm-dark-green: #4A9A5E;
  --dm-contrast-blue: #E5FFFD;
  --dm-white: #FFFFFF;
  --dm-black: #000000;
  --dm-off-white: #F8F8F8;

} */
  colors: {
    text: '#1F1717',
    primary: '#7fBD8F',
    secondary: '#531D42',
    background: '#BEDBBD',
    mediumGrey: '#B1B1B1',
    lightGrey: '#E7E7E7',
    offWhite: '#F8F8F8',
    greige: '#F4F2F2',
    red: '#BB4C4F',

    mainLightBlue: '#BEDBBD',

    blue: '#524F6C',
    mediumBlue: '#8E8BAD',
    lightBlue: '#DEDEF0',

    mainGreen: '#7fBD8F',
    ivory: '#E7E8DA',
    lightGreen: '#BEDBBD',
    gray: '#F2EBEB',
    white: '#FFFFFF',
    black: '#000000',

    salmon: '#FE756C',
    peach: '#FEB09F',
    lightPeach: '#FFD7CA',
    bluePurple: '#524F6C',
    lavender: '#BBBBF2',
    lightLavender: '#DEDEF0',
  },
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
    medium: {
      fontFamily: 'heading',
      lineHeight: 'body',
      fontWeight: 'medium',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      overflowWrap: 'break-word',
      fontSize: 3,
    },
    h1: {
      variant: 'text.heading',
      fontSize: 7,
    },
    h2: {
      variant: 'text.heading',
      fontSize: 6,
    },
    h3: {
      variant: 'text.heading',
      fontSize: 5,
    },
    h4: {
      variant: 'text.medium',
      fontSize: 4,
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
  },
  buttons: {
    primary: {
      fontWeight: 'medium',
      color: 'secondary',
      bg: 'mainLightGreen',
      border: '1.5px solid',
      br: '3px',
      p: '7px 10px',
      outline: 'none',
      ':active': {
        color: 'white',
        bg: 'primary',
      },
      ':hover': {
        backgroundColor: 'BEDBBD',
        color: 'white',
      },
      ':focus': {
        outline: theme => `1px dotted ${theme.colors.primary}`,
      },
    },
    secondary: {
      fontWeight: 'medium',
      color: 'text',
      bg: 'lightGrey',
      border: '1.5px solid',
      br: '3px',
      p: '7px 10px',
      ':focus': {
        outline: theme => `1px dotted ${theme.colors.text}`,
      },
      ':hover': {
        backgroundColor: 'text',
        color: 'white',
      },
    },
    accent: {
      fontWeight: 'medium',
      color: 'white',
      bg: 'primary',
      border: '1.5px solid',
      br: '3px',
      p: '7px 10px',
      ':focus': {
        outline: theme => `1px dotted ${theme.colors.primary}`,
      },
    },
    disabled: {
      fontWeight: 'medium',
      color: 'grey',
      bg: 'lightGrey',
      border: '1.5px solid',
      br: '3px',
      p: '7px 10px',
      ':focus': {
        outline: theme => `1px dotted ${theme.colors.primary}`,
      },
    },
  },
};

export default theme;

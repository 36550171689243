export const GenderOptions = [
  'men',
  'women',
  'nonbinary people',
  'men & women',
  'men & nonbinary people',
  'women & nonbinary people',
  'people of all genders',
];

export const DefaultPrivacy = 'private';

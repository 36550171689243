export const Pronouns = [
  { name: 'he/him' },
  { name: 'she/her' },
  { name: 'they/them' },
  { name: 'it/its' },
  { name: 'ze/hir' },
  { name: 'ze/zir' },
  { name: 'none' },
  { name: 'any' },
];

export const pageFAQSx = {
  '.faq-container': {
    marginBottom: '50px',
  },

  '.faq-button': {
    fontSize: '24px',
    cursor: 'pointer',
    marginBottom: '10px',
  },

  '.faq-section': {
    marginTop: '10px',
    marginLeft: '30px',
    maxWidth: '1000px',
  },

  '.faq-button i': {
    width: '30px',
    color: '#BEDBBD',
  },

  '.faq-section h4': {
    fontSize: '16px',
    marginTop: '20px',
  },

  '.faq-section div': {
    fontSize: '16px',
    lineHeight: '20px',
    marginBottom: '16px',
  },

  '.faq-section: p': {
    fontSize: '16px',
    lineHeight: '20px',
    marginBottom: '16px',
  },

  '.legal': {
    fontFamily: 'monospace',
    marginBottom: '16px',
  },

  '.cover': {
    width: '300px',
    float: 'left',
  },

  '.modal-box': {
    backgroundColor: '#f4f2f2',
    padding: '40px',
    textAlign: 'left',
    fontSize: '18px',
    marginBottom: '30px',
  },

  icon: {
    textAlign: 'center',
    width: '100%',
  },
};

// theme-sensitive page content styles (excluded from Navbar)
export const pageContentSx = {
  a: {
    fontWeight: 'bold',
    color: 'primary',
    ':hover': {
      color: 'secondary',
    },
  },
};

const redFlags = [
  // Universal Red Flags
  'Claims to be “brutally honest” but is actually just brutal',
  'Compulsively makes peace signs with hands in photos',
  'Drinks 187823 cups of coffee a day and still falls asleep in class',
  'Screen time is 13 hours a day',
  'Hasn’t done laundry in four weeks',
  '“I love traveling and going on adventures”',
  'Instagram bio reaches the character limit',
  'Wakes up after sunset',
  'Responds to texts after 3 business days',
  'Enjoys steak “well done”',
  'Only responds to Snapchat',
  'Uses 😂 rather than 💀',
  'You think the Aphrodite (/Marriage Pact) questions are good',
  'Denies having red flags',
  'Is banned from Twitter',
  'Seems like a round-earther',
  '“god you have so many red flags you’re such a shit person”',
  'Holds scissors with the blades facing up',
  'Doesn’t like dark chocolate',
  'Doesn’t eat pizza crust',
  'Doesn’t know how to calculate tip without a calculator',
  'Doesn’t wear enough sunscreen',
  'Cannot parallel park',
  'Takes the first item off the shelf when shopping rather than looking for the best one',
  'Doesn’t check expiration dates when shopping for food',
  'Doesn’t know their HUID number',
  'Doesn’t drink enough water',
  'Hates cilantro',
  'Has a cracked phone screen',
  'Enjoys cold showers',
  'Never takes out the trash',
  'Shuffles playlists and skips to the song they want to listen to rather than just clicking on the song',
  'Thinks sunglasses actually make them look cool ',
  'Wears sweatpants too often',
  'Never learned how to swim',
  'Probably can’t swim',
  'Cannot cut paper in a straight line',
  'Sniffed glue as a child',
  'Sniffed sharpies as a child',
  'Sniffed white out as a child',
  'Enjoys the smell of gasoline',
  'Tells friends when they’re sent memes that they’ve seen before',
  'Probably snorted smarties at least once in their life',
  'Secretly thinks they’re good at dancing when they aren’t',
  'Secretly thinks they’re better at singing than they actually are',
  'Has allergies',
  'Doesn’t moisturize their hands enough',
  'Gives up on hobbies way too easily',
  'Doesn’t pursue anything they’re not immediately good at',
  'Hasn’t read an actual book in months',
  'Eats food past their expiration date',
  'Spends too much money',
  'Checks themselves out in every window they pass by while walking',
  'Thinks they are the main character ',
  'Uses too many paper towels',
  'Doesn’t fold toilet paper properly',
  'Has stomach problems',
  'Needs to work on their flexibility',
  'Hips already hurt',
  'Asks questions on Youtube they could easily just Google',
  'Binges every show they like all in one day',
  'Likes air conditioning a little too much',
  'Has bad posture',
  'Slouches too much',
  'Backpack straps are not at the right length',
  'Doesn’t tie their shoes properly',
  'Still sings the ABCs when figuring out alphabetical order',
  'Lowkey enjoys getting sung “Happy Birthday” to in public',
  'Rates themselves too highly on a scale from 1 to 10',
  'Takes the phrase “treat others the way you want to be treated” a little too literally',
  'Is too much of a people pleaser',
  'Would rather be right than preserve a friendship',
  'Speaks too loudly on public transportation',
  'Laughs too loudly in public',
  'Cannot balance on one foot',
  'Doesn’t eat enough vegetables',
  'Has dropped food on the ground and still eaten it',
  'Needs to call their loved ones more often',
  'Has too many unread emails in their inbox',
  'Doesn’t own a lint roller',
  'Has too many pairs of shoes',
  'Sneezes into their hands ',
  'Apologizes to inanimate objects that they crash into',
  'Says “sorry” too much',
  'Doesn’t separate recycling and trash',
  'Was chosen last in P.E. as a child',
  'Peeled the wrappers off of crayons as a child',
  'Cannot squash insects on their own',
  'Still scared of spiders',
  'Gives the other person the “smaller” half when asked to share',
  'Hasn’t washed their bedsheets in weeks',
  'Consistently sleeps through their alarms',
  'Secretly actually enjoys “Baby” by Justin Bieber',
  'Constantly reminisces about how amazing 2016 summer was',
  'Enjoys playing devil’s advocate',
  'Doesn’t use their own Netflix subscription',
  'Still uses their ex’s Netflix account',
  'Still has an imaginary friend',
  'Has used the same password for everything since they were 12',
  'Doesn’t like broccoli',
  'Orders the same thing every time at a restaurant',
  'Cannot navigate with a regular map',
  'Constantly loses track of everything',
  'Cannot handle spice',
  'Compulsively writes "lol" after every text no matter the content',
  'Compulsively writes "haha" after every text no matter the content',
  'Texts with perfect capitalization and punctuation',
  'Owns LED lights in their room',
  'Investing in Robinhood',
  'Wear their mask under their nose',
  'Constantly pulling all nighters for classes',
  'Does not wear pants during zoom calls',
  'Says “Ethereum” or “Bitcoin” several times a day',
  'Reshares every birthday shout out they get on Instagram',
  'Joins clubs just for the Patagonias',
  'Says “if they wanted to they would,” but actually wants to, and yet doesn’t',
  'Wears Anti Social Social Club, Supreme, or Thrasher',
  'Carries a skateboard around campus for the aesthetic but can’t skate ',
  '“[insert name] would like to connect with you on Linkedin”',
  'Is a premed student because they “want to help people”',
  '“Can cook” but only knows how to make eggs',
  'Wears shoes on their bed',
  'Does not peel bananas correctly',
  'Has not called their parent(s) recently',
  'A little too fond of playing devil’s advocate',
  'Has stolen utensils from the dining hall (please put them back)',
  'Leaves water on the floor after they shower',
  'Does not separate their whites and colors for laundry',
  'Does not know how to scoop soup with a soup ladle while spilling a little soup in the process',

  // Men Red Flags
  'Probably holding a fish in dating profile.',
  'Blasts SOUR after frat parties to express true emotions',
  'Blasts Marvin’s Room after frat parties to express true emotions',
  'Blasts Lucid Dreams after frat parties to express true emotions',
  'Plays devil’s advocate with a little too much vigor',
  'Doesn’t own a bedframe',
  'Name starts with J',
  'Protein shaker bottle doubles as a regular water bottle',
  'Reddit is default form of entertainment',
  'Wakes up every morning thinking “last night was a movie”',
  'Navy blue sheets',
  'Wants to switch lives with Pete Davidson',
  'Puts a Supreme sticker over laptop logo',
  'Listens to Call Her Daddy for sex advice',
  'Has a Kanye album poster on the wall',
  'Puts tie on your head when drunk and thinks it’s funny',
  'No trash can in the bathroom',
  'Takes blurry selfies where only hair is visible',
  'Wears shorts in 20 degrees because “it’s not cold outside”',
  'Drinks only protein shakes',
  'Relates to Drake',
  'Relates to Kanye',
  'Relates to Pete Davidson (in their dreams)',
  'Relates to Mitch McConnell',
  'Wears Vineyard Vines',
  'Salmon shorts',
  'Patagonia',
  'Would rather die than become a vegetarian',
  '“I know a spot”',
  "Doesn't drink water or eat vegetables ",
  'Toxic in COD lobby',
  'Bought an NFT and actually thinks it’s worth something',
  'Worships Elon Musk',
  'Tells everyone that hey’re making the next Facebook',
  'Religiously drinks 3 glasses of milk every day',
  'Says “sheeeeeeesh” when at a loss for words',
  'Thinks their terrible British accent is hilarious and brilliant',
  'Believes that women don’t fart',
  'Only wears muscle tanks',
  'Genuinely enjoyed Wolf of Wall Street',
  'Deez nuts jokes',
  'Seriously contemplated filming a “day in the life”',
  '“Builder, Innovator, Entrepreneur | Forbes 30 Under 30”',
  'Pulls 🅿️',
  'Thinks they could pull off long hair',
  'Owns a rainbow keyboard',
  'Has protein powder, pre-workout, and creatine on hand',
  'Listens to Weezer',
  'Uses 3-in-1 body wash-conditioner-shampoo',
  'Socks and sandals, need I say more?',
  'Plays League of Legends',
  'Listens to Machine Gun Kelly',
  'Listens to Eminem ',
  'Talks about post nut clarity',
  'Hasn’t cried in a few years',
  'Says the words “no nut november” ',
  '“Look how it just beads off”',
  'Always shirtless at home',
  'No skincare routine',
  'Powerlifts',

  // Women Red Flags
  'Listens to true crime podcasts to fall asleep',
  'Owns 9 canvas tote bags that would need to be used 100 times each to offset their environmental impact relative to paper bags',
  'Claims to be completely self-actualized after going to therapy once',
  'Rejects potential dates based on their sign',
  'Gets icked by life',
  'Posts that one beach picture where they’re in a bikini kneeling with their hands in their hair',
  'Wears scarves as shirts',
  'Secretly wouldn’t mind if Timothee Chalamet gave them chlamydia',
  'Thinks liking dogs is a personality trait',
  'No sense of direction, both GPA and GPS',
  'Says their kink is “liberal men who look like Republicans”',
  'Justifies every decision with “the girls who get it, get it”',
  'Claps back at haters with “the girls who don’t, don’t”',
  'Ends texts with “uwu” or “owo.” uwu',
  'Is not like other girls',
  '“$10 off an $89 skirt at Aritzia? What a steal”',
  'Records TikTok dances in their dorm room',
  'Owns 12 succulents that are all dead',
  'Enjoys “propagating plants”',
  'Navy blue Longchamp tote',
  'Brags about thrifting',
  '“Dress is from Sheeeeein”',
  'Wants you to give them flowers but doesn’t want to have to ask you to give them flowers because it would ruin the integrity of the gesture',
  'Seriously contemplated becoming a YouTuber',
  'Comments “So gorg ily 😍” on posts from people they actually hate',
  'Has already planned out their wedding aesthetic',
  'Has too many bruises all the time',
  'Overpacks for every trip',
  'Takes a picture of every sunset they see',
  'Overshares on their Instagram/Snapchat story',
  'Starts any conversation with “so anyways”',
  'Has a polaroid wall',
  'Has a Glossier sticker on their Hydroflask',
  'Euphoria-obsessed',
  'Has enough pillows to cover their entire bed',
  'Uses the 🥺emoji',
  'Wear glasses with no prescription lenses',
  'Wear blue light glasses',
  'Makes Trader Joe’s their personality ',
  'Goes to SoulCycle',
  'Carries around the Lululemon bag every day as a normal bag',
  'Walking Lululemon ambassador',
  'Calls going to Target an adventure',
];

export default redFlags;

import teams from './teams.json';

export const Teams = [
  {
    teamName: 'Leads',
    teamColor: '#1d5a4d',
    members: teams['Leads'],
  },
  {
    teamName: 'Web',
    teamColor: '#C6C099',
    members: teams['Web'],
  },
  {
    teamName: 'Design',
    teamColor: '#209049',
    members: teams['Design'],
  },
  {
    teamName: 'Algorithm',
    teamColor: '#98C0AD',
    members: teams['Algorithm'],
  },
  {
    teamName: 'Statistics',
    teamColor: '#998F76',
    members: teams['Statistics'],
  },
  {
    teamName: 'Business',
    teamColor: '#B1B1B1',
    members: teams['Business'],
  },
];

export const generateDateTimes = startDateStr => {
  const startDate = new Date(startDateStr);
  let endDate = new Date(startDateStr);
  endDate.setDate(startDate.getDate() + 6);
  var dateTimes = [];

  for (var d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
    for (let hour of [11, 14, 18]) {
      const date = new Date(d).setHours(hour, 0, 0);
      dateTimes.push(new Date(date));
    }
  }

  return dateTimes;
};

const generateDates = startDateStr => {
  let dates = [];
  for (const date of generateDateTimes(startDateStr)) {
    dates.push(date.getMonth() + 1 + '/' + date.getDate());
  }

  return [...new Set(dates)];
};

const startDates = {
  Harvard: 'September 20, 2024',
};

const generateAllDateTimes = () => {
  const dateTimes = {};
  for (const [college, date] of Object.entries(startDates)) {
    dateTimes[college] = generateDateTimes(date);
  }

  return dateTimes;
};

const generateAllDates = () => {
  const dates = {};
  for (const [college, date] of Object.entries(startDates)) {
    dates[college] = generateDates(date);
  }

  return dates;
};

const dayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const generateAllDaysOfWeek = () => {
  const daysOfWeek = {};
  for (const [college, date] of Object.entries(startDates)) {
    const days = [];
    const day1 = new Date(date).getDay();
    for (let i = 1; i < 8; i++) {
      days.push(dayNames[(day1 + i) % 7]);
    }
    daysOfWeek[college] = days;
  }

  return daysOfWeek;
};

const getSurveyEndDate = () => {
  const endDates = {};
  for (const [college, date] of Object.entries(startDates)) {
    let endDate = new Date(date);
    endDate.setDate(endDate.getDate() - 1);
    endDates[college] =
      endDate.getMonth() + 1 + '/' + endDate.getDate() + ' at 11:59PM';
  }

  return endDates;
};

export const dateTimes = generateAllDateTimes();
export const collegeToDates = generateAllDates();
export const daysOfWeek = generateAllDaysOfWeek();
export const surveyEndDate = getSurveyEndDate();

// Note: timezones in JavaScript of the form "Etc/GMT+{number}" are written backwards
// i.e. "Etc/GMT+5" (Eastern) actually represents GMT-5
// copy timezones from here: https://publib.boulder.ibm.com/tividd/td/TWS/SC32-1274-02/en_US/HTML/SRF_mst273.htm
export const timezones = {
  'GMT -5 (American Central)': 'Etc/GMT+5',
  'GMT -4 (American Eastern)': 'Etc/GMT+4',
};
